import React from 'react'
import Nav from './Navbar/Nav'
import Footer from './Footer/Footer'
import "./Terms.css"

function CancellationPolicy() {
  return (
   <>
   <Nav />
   <br />
   <br />
   <br />
   <br />
     <div className='Procedure container '>
    <div className='d-flex justify-content-center mt-4'>
        <div className='huhuhjijijo'>REFUND POLICY</div>
    </div>
<br />
<div className='hfv '>
    <div className='ytgfhv'>
    <div >
        <div className='hbugfdxfgh'>At The West Wind, we understand that plans may change, Our refund policy is as follows:</div>
    </div>
    <br />
    <div>
        <div className='hbugfdxfgh'>1.Cancellations made more than 14 days prior to the check-in date will receive a full refund.</div>
    </div>
    <br />
    <div >
        <div className='hbugfdxfgh'>2. Cancellations made between 7 and 14 days prior to the check-in date will receive a 50% refund.</div>
    </div>
    <br />
    <div >
        <div className='hbugfdxfgh'>3. No refunds will be issued for cancellations made within 7days of the check-in date.</div>
    </div>
    <br />
    <div >
        <div className='hbugfdxfgh'>4. The change of date will be allowed on availability </div>
    </div>
    <br />
    <div >
        <div className='hbugfdxfgh'>Please allow up to 14 days for the refund to be processed.</div>
    </div>
 
    </div>
    </div>
    <br />
    <br />
  </div>
  <Footer />
   </>
  )
}

export default CancellationPolicy