import React from 'react'
import Nav from './Navbar/Nav'
import Footer from './Footer/Footer'
import "./Terms.css"

function Privacypolicy() {
  return (
    <>
    <Nav />
   <br />
   <br />
   <br />
   <br />
     <div className='Procedure container '>
    <div className='d-flex justify-content-center mt-4'>
        <div className='huhuhjijijo'>PRIVACY POLICY</div>
    </div>
<br />
<div className='hfv '>
    <div className='ytgfhv'>
    <div >
        <div className='hbugfdxfgh'>At The West Wind, we are committed to protecting your privacy. This policy outlines how we collect, use, and safeguard your personal information:</div>
    </div>
    <br />
    <div >
        <div className='hbugfdxfgh'><span className='hehgcgh'>1. Information Collected</span>
        <div className='hbugfdxfgh'>- We collect personal information such as name, contact details, and payment information for reservation purposes.</div>
        </div>
    </div>
    <br />
    <div>
        <div className='hbugfdxfgh'><span className='hehgcgh'>2. Use of information</span> 
        <div className='hbugfdxfgh'>- Personal information is used solely for reservation and communication purposes. We do not share or sell this information to third parties.</div>
        </div>
    </div>
    <br />
    <div >
        <div className='hbugfdxfgh'><span className='hehgcgh'>3. Security</span>
        <div className='hbugfdxfgh'>- We implement industry-standard security measures to protect your personal information from unauthorized access or disclosure.</div>
        </div>
    </div>
    <br />
    <div >
        <div className='hbugfdxfgh'><span className='hehgcgh'>4.Cookies</span>
        <div className='hbugfdxfgh'>- Our website may use cookies to enhance your browsing experience. You can choose to disable cookies in your browser settings.</div>
        </div>
    </div>
    <br />
    <div >
        <div className='hbugfdxfgh'><span className='hehgcgh'>5. Third Parties</span>
        <div className='hbugfdxfgh'>- We may use trusted third-party services to process payments and manage reservations. These parties have their own privacy policies.</div>
        </div>
    </div>
    <br />

    <div >
        <div className='hbugfdxfgh'><span className='hehgcgh'>6. Changes to Policy</span>
        <div className='hbugfdxfgh'>- We may update our privacy policy from time to time. Any changes will be posted on our website.</div>
        </div>
    </div>
    <br />

    <div >
        <div className='hbugfdxfgh'>if you have any questions or concerns regarding your privacy, please contact us at info@thewestwindooty.com</div>
    </div>
    </div>
    </div>
    <br />
    <br />
  </div>
  <Footer />
   </>
  )
}

export default Privacypolicy