import React from 'react'
import './Nav.css';
import { Link } from 'react-router-dom';

function Nav() {
  
  return (
    <>


<div class="modal fade" id="exampleModalOne" tabindex="-1" aria-labelledby="exampleModalLabelOne" aria-hidden="true">
  <div class="modal-dialog modal-fullscreen">
  <div class="modal-content" >

  <div > 
        
        <button type="button" class=" clopop" data-bs-dismiss="modal" aria-label="Close"><img src='/images/c5.png' className='closeimgae'  alt=''/></button>
      </div>
      
<div className='navbartyty'>
  <div>
  <a href="/home">  <div className='Abgbg'>Home</div></a>
  <a href="/aboutus">  <div className='Abgbg'>About Us</div></a>
  <a href="/contactus"> <div className='Abgbg'>Contact Us</div></a>
  <a href="/rooms">  <div className='Abgbg'>Rooms</div></a>
  {/* <a href="/blog">  <div className='Abgbg'>Blogs</div></a> */}
  <a href="/Booking">   <div className='Abgbg'>Book Now</div></a>
      <div className='Abgbg'><a href="tel:+91 80727 54415"> +91 80727 54415 / 63796 79146</a></div>
      </div>
      </div>
 </div>
 </div>
 </div>



<div className='headnav'>
<div className='nonon'>
<div className='content'>

  <div className='text-light d-flex align-items-center' >
  <Link to="/aboutus"><div id='loppo'>About Us</div></Link>
     <a href="/contactus"> <div id='loppo' className='mx-4'>Contact Us</div></a>
     <a href="/rooms">  <div id='loppo' className='mx-4'>Rooms</div></a>
     {/* <a href="/blog">  <div id='loppo' className='mx-4'>Blogs</div></a> */}

  </div>
  <div className='text-light' id='logo'>
  <a href="/home"> <h3 className='logo'><img src='/images/hi.png' className='logoi' alt='' /><img src='/images/hl.png' className='logok' alt='' /></h3></a>
  </div>
  <div className='text-light d-flex align-items-center '>
  <div className='me-5 nlop'><a href="tel:+91 80727 54415" className='LinksFooter mt-3' >+91 80727 54415 <span className='hgjgjghfchgch'>/ 63796 79146</span></a></div>
      <a href="/Booking"><div className='book nlop'>Book Now</div></a>
  <div className='bar' data-bs-toggle="modal" data-bs-target="#exampleModalOne"><img className='barimage' alt='' src='/images/bar.png'></img></div>
  </div>
  
  </div> 

  </div> 



</div>


    </>
  )
}

export default Nav