import React, { useEffect, useRef, useState } from 'react'
import './Details.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios'
import { Base_url } from '../Url';
import Footer from '../Footer/Footer';
import Nav from '../Navbar/Nav';

function Details() {
 const refbooknow = useRef()
  const location = useLocation();
  const navigate = useNavigate()
  const [adult , setsdult] = useState(0)
  const [child , setchils] = useState(0)
  const [firstdate , setfirstdate] = useState("")
  const [seconddate , seteconddate] = useState("")
  const [total , settotal] = useState("")
  const [charge , setcharge] = useState("")
  const [tax , settax] = useState()
  const [totalcharge , settotalcharge] = useState()
  const [Name , setName] = useState("")
  const [Email , setemail] = useState("")
  const [Phone , setphone] = useState("")
  const [adultT , setsdultT] = useState("")
  const [childT, setchilsT] = useState(0)
  const [Roomname , setroomname] = useState("")
  const [Roomid , setid] = useState("")
  const [loading, setLoading] = useState(false);

  const [fiftyPer , setFiftyPer] = useState("")



console.log("Total charge" , fiftyPer);



  useEffect(() => {
    if(location.state !== null){
// console.log("Location inner" , location.state.adultT);
      setsdult(location.state.adult)
      setchils(location.state.child)
      setfirstdate(location.state.firstdate)
      seteconddate(location.state.seconddate)
      settotal(location.state.totalnight)
      setcharge(location.state.charge)
      setsdultT(location.state.adultT)
      setchilsT(location.state.childT)
      setroomname(location.state.Roomname)
      settax(location.state.tax)
      settotalcharge(location.state.totalamount)
      setid(location.state.id)
      setFiftyPer(location.state.totalamount/2)
    }
  
 })

 if(Name.length > 0 && Email.length >0 && Phone.length > 0){
  refbooknow.current.classList.add("boolnowT")
 }
 function loadRazorpay() {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.onerror = () => {
      alert('Razorpay SDK failed to load. Are you online?');
    };
    script.onload = async () => {
      try {
        const result = await axios.post(`${Base_url}/create-order`, {
          amount: fiftyPer + '00',
        });
        const { amount, id: order_id, currency } = result.data;
        const {
          data: { key: razorpayKey },
        } = await axios.get(`${Base_url}/get-razorpay-key`);
        const options = {
          key: razorpayKey,
          amount: amount.toString(),
          currency: currency,
          name: 'example name',
          description: 'example transaction',
          order_id: order_id,
          handler: async function (response) {
            const result = await axios.post(`${Base_url}/pay-order`, {
              amount: amount,
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
              Name : Name,
              Email : Email,
              Phone : Phone,
              adult : adult,
              child : child,
              tax:tax,
              charge:charge,
              totalcharge:fiftyPer,
              Roomname : Roomname,
              total:total,
              firstdate:firstdate,
              seconddate:seconddate,
              Roomid : Roomid
            });
            // alert(result.data.msg);
            navigate("/success")
          },
          prefill: {
            name: 'example name',
            email: 'email@example.com',
            contact: '111111',
          },
          notes: {
            address: 'example address',
          },
          theme: {
            color: '#80c0f0',
          },
        };

        setLoading(false);
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      } catch (err) {
        alert(err);
        setLoading(false);
      }
    };
    document.body.appendChild(script);
  }




  return (
 <>
 <Nav />
 <div className='details'>
    <div className='detailsinner'>
<div className='headcust'>
    <div className='startguests' ><Link to="/Booking"><div><img src='/images/arrpop.png' alt='' className='arrowpop' /></div></Link><div className='guest'>Guest Details</div></div>
<br />

    <div className='detailacust'>
        <div className='customOne' >
            <div className='detailsOne' >
            <div className='oneinner'>
                <div className='guestOne'>Guest Information</div>
                <br />
                <div className='formdef'>
                    <div className='gofromde'>
                    <div className='formOnede'>
                        <input type='text' placeholder='Name' value={Name} onChange={(e)=>setName(e.target.value)}/>
                        </div>
                        <div className='formOnede' id='ojgvubijbk'>
                        <input type='text' placeholder='Email' value={Email} onChange={(e)=>setemail(e.target.value)} />
                        <div className='njn'>This is the email we will send your confirmation to</div>
                    </div>
                    </div>

                    <div className='formOnede' id='ojgvubijbk'>
                        <input type='text' placeholder='Phone' value={Phone} onChange={(e)=>setphone(e.target.value)} />
                    </div>
                </div>
<br />
                <div className='policolo'>Polices :</div>
<br />
                <div className='d-flex surya'>
                    <div><div className='inhihih'>Check-In</div><div className='sunbhb'>After 02:00 pm</div></div>
                    <div><div className='inhihih'>Check-out</div><div className='sunbhb'>Before 11:00 am</div></div>
                </div>

<br />
                <div>

                    <div className='inhihih'>Cancel Policy</div>
<br />
                    <div className='sunbhb'>If you require to cancel or amend a reservation more than 7 days prior to arrival, the deposit will be<br /> refunded; less than 7 days prior to arrival or in case of No-Show, the full amount of the stay will be <br />charged as cancellation fees.</div>
                </div>
            </div>
            </div>
        </div>
        <div className='customtwo' >
             <div className='cunstheard'>
                <div className='customtwoinner'>
                         <div className='guestTwo' >Your Stay</div>
                         <br />
                         <div className='d-flex surya'>
                    <div><div className='inhihihT'>Check-In</div><div className='kokokokok'>After 02:00 pm</div></div>
                    <div><div className='inhihihT'>Check-out</div><div className='kokokokok'>Before 11:00 pm</div></div>
                </div>
<br />
<div className='kokokokok'>{firstdate} - {seconddate }</div>
<div className='kokokokok'><span>{location.state !== null ?  location.state.adult > 0 ? adult : adultT  : adult}</span> Adults<span className='newchild'>{location.state !== null ?  location.state.child > 0 ? child : childT  : child}</span> Child</div>
{/* <div className='kokokokok'><span>{child}</span> Children</div> */}
<br />
<div className='kokokokok'>{Roomname}</div>
<div className='kokokokok'>{total} Night</div>
<br />
<div className='inhihihT'>Add a Room</div>
{/* <br /> */}
<div><hr /></div>
<div className='d-flex justify-content-between'><div className='kokokokok'>Room’s Charge</div><div className='kokokokok'>{charge}</div></div>
<div className='d-flex justify-content-between'><div className='kokokokok'>Taxes & GST</div><div className='kokokokok'>{tax}</div></div>
<div><hr /></div>
<div className='d-flex justify-content-between'><div className='inhihihT'>TOTAL</div><div className='kokokokok'>{totalcharge}</div></div>
<div className='d-flex justify-content-between'><div className='inhihihT'>Payable Amount</div><div className='kokokokok'>{fiftyPer}</div></div>


                </div>
             </div>
        </div>
    </div>


    <div className='container d-flex justify-content-center'>
        <div className='boolnow' ref={refbooknow}  disabled={loading} onClick={loadRazorpay} >Book Now</div>
    </div>
    <br />
    <br />
    <br />
</div>

    </div>
 </div>
 <Footer />
 </>
  )
}

export default Details