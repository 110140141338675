import React, { useState } from 'react'
import "./Rooms.css"
// import { Link } from 'react-router-dom'
import axios from 'axios'
import { Base_url } from '../Url';
// import { useNavigate } from 'react-router-dom';
import Nav from '../Navbar/Nav';
import Footer from '../Footer/Footer';
import Transition from '../Transition/Transition';
import { Helmet } from "react-helmet";

function Rooms() {
  // const navigate = useNavigate()

  const[Name , setName] = useState("")
  const[Email , setemail] = useState("")
  const[Surename , setsurename] = useState("")
  const[Phone , setphone] = useState("")
  const[Subject , setsubject] = useState("")
  const[Message , setmessage] = useState("")

  const conttactinfo =async()=>{
    const result = await axios.post(`${Base_url}/contact-details` , {
      Name : Name,
      Surename:Surename,
      Email : Email,
      Phone:Phone,
      Subject : Subject,
      Message:Message
     
    })

    alert(result.data.msg)
 
  }


  return (
  <>
   <Helmet>
                <meta charSet="utf-8" />
                <title>Our Inviting Rooms & Suites at The West Wind</title>
              <meta
    name="description"
    content="Unwind in our thoughtfully designed rooms and suites, each offering a blend of modern comfort and traditional charm. Find your perfect retreat amidst the scenic beauty of Ooty.
    "
  />
            </Helmet>
<Nav />
<div className='heroR'>
    <img src='/images/r1.png' className='lan' id="heromobtwo" alt='' />
    <img src='/images/r111.png' className='lan' id="heromob" alt='' />
    </div>

    <div className='herocenter'>
  <div className='container-fluid' >
   
    <div>
 <h3 className='text-light jjijij' data-aos="fade-up" data-aos-duration="2000">Rooms & Suites</h3>
  <div className='text-light jijijtw' data-aos="fade-up" data-aos-duration="2000">Warmth. Care. Peace
  </div>
  </div>
  
  </div>


</div>



<div className='roomespop'>
    <div className='roomsinner'>
<div className='OneRoom'>

        <img src='/images/r11.png'  data-aos="fade-up" alt='' data-aos-duration="2000"/>
   

        <div>
          <br />
      <div className='headrommnam' data-aos="fade-up" data-aos-duration="2000">Double Room With Patio</div>
      <div className='hnjhnhn' data-aos="fade-up" data-aos-duration="2000"><span>100m2</span>/ Mountain view / 2 Adults 1 Child</div>
<br />
      <div className='exploihbhjn' data-aos="fade-up" data-aos-duration="2000">Elevate your senses with awe-inspiring forest and 
      landscape views from your private patio. Enjoy serene
      mornings and tranquil evenings in this thoughtfully
      designed retreat.</div>
<br />
<div className='dopopo'>
     <a href="/DoubleRoomWithPatio"> <div className='dicoverrromm' data-aos="fade-up" data-aos-duration="2000"> Discover More</div></a>
    </div>
    </div>
</div>



<div className='TwoRoom'>

<img src='/images/r22.png' alt='' data-aos="fade-up" data-aos-duration="2000" />
<div>
<br />
      <div className='headrommnam' data-aos="fade-up" data-aos-duration="2000">King Room With Forest View</div>
      <div className='hnjhnhn' data-aos="fade-up" data-aos-duration="2000"><span>100m2</span>/ Mountain view / 2 Adults 1 Child</div>
<br />
      <div className='exploihbhjn' data-aos="fade-up" data-aos-duration="2000">Indulge in nature's embrace with stunning forest
      vistas. The King Room offers comfort and charm in the
      heart of Ooty.</div>
<br />
<div className='dopopo'>
<a href="/KingRoomWithForestView"> <div className='dicoverrromm' data-aos="fade-up" data-aos-duration="2000"> Discover More</div></a>
    </div>
    </div>
</div>
    </div>
</div>

<div className='roomespop'>
    <div className='roomsinner'>
<div className='OneRoom'>

        <img src='/images/r33.png ' alt='' data-aos="fade-up" data-aos-duration="2000"/>
        <div>
<br />
      <div className='headrommnam' data-aos="fade-up" data-aos-duration="2000">Family Room</div>
      <div className='hnjhnhn' data-aos="fade-up" data-aos-duration="2000"><span>100m2</span>/ Mountain view / 2 Adults 1 Child</div>
<br />
      <div className='exploihbhjn' data-aos="fade-up" data-aos-duration="2000">Spacious and inviting, the Family Room provides a
      perfect sanctuary for families, combining comfort and
      convenience for an unforgettable Ooty escape.
         </div>
<br />
<div className='dopopo'>
<a href="/FamilyRoom"> <div className='dicoverrromm' data-aos="fade-up" data-aos-duration="2000"> Discover More</div></a>
    </div>
    </div>
    
</div>
<div className='TwoRoom'>

<img src='/images/r44.png' alt='' data-aos="fade-up" data-aos-duration="2000" />
<div>
<br />
      <div className='headrommnam' data-aos="fade-up" data-aos-duration="2000">Superior Room</div>
      <div className='hnjhnhn' data-aos="fade-up" data-aos-duration="2000"><span>100m2</span>/ Mountain view / 2 Adults 1 Child</div>
<br />
      <div className='exploihbhjn' data-aos="fade-up" data-aos-duration="2000">Experience elegance and tranquility in our
      thoughtfully designed Superior Room, a serene retreat
      amidst Ooty's picturesque hills.
        </div>
<br />
<div className='dopopo'>
<a href="/SuperiorRoom"> <div className='dicoverrromm' data-aos="fade-up" data-aos-duration="2000"> Discover More</div></a>
      </div>
    </div>

</div>
    </div>
</div>

<div className='koplopop' >

<div className='jhgbujbh d-flex justify-content-center'>
</div>

<div  id='infocontact'>
  <br />
  <br />
  <div className='container d-flex justify-content-center'>
 <h1 data-aos="fade-up" data-aos-duration="2000">Contact us to Enquire</h1> 
 </div>
<form>
<div className='container'>
<div className='row' id='formcon'>
  <div className='col-lg-3'></div>
  <div className='col-lg-3'>
  <input type='text' placeholder='Name' value={Name} onChange={(e)=>setName(e.target.value)} data-aos="fade-up" data-aos-duration="2000"/>
    <br />
    <br />
    <input type='text' placeholder='Surename' value={Surename} onChange={(e)=>setsurename(e.target.value)} data-aos="fade-up" data-aos-duration="2000"/>
    <br />
    <br />
    <input type='text' placeholder='Phone' value={Phone} onChange={(e)=>setphone(e.target.value)} data-aos="fade-up" data-aos-duration="2000" />
    <br />
    <br />
    <input type='text' placeholder='Email' value={Email} onChange={(e)=>setemail(e.target.value)} data-aos="fade-up" data-aos-duration="2000" />
  </div>

  <div className='col-lg-3 subjectone'>
    <input type='text' placeholder='Subject' value={Subject} onChange={(e)=>setsubject(e.target.value)} data-aos="fade-up" data-aos-duration="2000" />
    <br />
    <br />
    <input type='text' placeholder='Message' value={Message} onChange={(e)=>setmessage(e.target.value)} data-aos="fade-up" data-aos-duration="2000" />
  </div>
  <div className='col-lg-3'></div>
    
</div>
</div>

</form>


<div className=' container discovercon' onClick={conttactinfo} data-aos="fade-up" data-aos-duration="2000">
Submit
</div>
<br />
<br />
<br />
</div>

  </div>  

  <Footer />

  </>
  )
}

export default Transition(Rooms)