import React from 'react'
import Nav from './Navbar/Nav'
import Footer from './Footer/Footer'
import "./Terms.css"
function Termsandcondition() {
  return (
    <>
    <Nav />
    <br />
    <br />
    <br />
    <br />
      <div className='Procedure container '>
     <div className='d-flex justify-content-center mt-4'>
         <div className='huhuhjijijo'>TERMS AND CONDITIONS</div>
     </div>
 <br />
 <div className='hfv '>
     <div className='ytgfhv'>
     <div >
         <div className='hbugfdxfgh'>Welcome to The West Wind, a charming bed & breakfast located in Ooty, The Nilgiris. By using our services, you agree to abide by the following terms and conditions:</div>
     </div>
     <br />
     <div >
         <div className='hbugfdxfgh'><span className='hehgcgh'>1. Booking and Reservations</span>
         <div className='hbugfdxfgh'>- All reservations are subject to availability.</div>
         <div className='hbugfdxfgh'>- A valid credit/debit card is required to secure a reservation.</div>
         <div className='hbugfdxfgh'>- Full payment is due upon check-in.</div>
         </div>
     </div>
     <br />
     <div>
         <div className='hbugfdxfgh'><span className='hehgcgh'>2. Cancellation and Refund Policy</span> 
         <div className='hbugfdxfgh'>- Cancellations made more than 14 days prior to the check-in date will receive a full refund.</div>
         <div className='hbugfdxfgh'>- Cancellations made between 7 and 14 days prior to the check-in date will receive a 50% refund</div>
         <div className='hbugfdxfgh'>- No refunds will be issued for cancellations made within 7 days of the check-in date.</div>
         </div>
     </div>
     <br />
     <div >
         <div className='hbugfdxfgh'><span className='hehgcgh'>3. Check-in and Check-out</span>
         <div className='hbugfdxfgh'>- Check-in time is from 2:00 PM onward.</div>
         <div className='hbugfdxfgh'>- Check-out time is by 11:00 AM.</div>
         </div>
     </div>
     <br />
     <div >
         <div className='hbugfdxfgh'><span className='hehgcgh'>4. Smoking and Pets</span>
         <div className='hbugfdxfgh'>- The West Wind is a non-smoking establishment. Smoking is strictly prohibited indoors.</div>
         <div className='hbugfdxfgh'>- Unfortunately, we do not allow pets on the premises.</div>
         </div>
     </div>
     <br />
     <div >
         <div className='hbugfdxfgh'><span className='hehgcgh'>5. Damages and Losses</span>
         <div className='hbugfdxfgh'>- Guests will be held responsible for any damages or losses incurred during their stay.</div>
         </div>
     </div>
     <br />
 
     <div >
         <div className='hbugfdxfgh'><span className='hehgcgh'>6. Conduct</span> 
         <div className='hbugfdxfgh'>- Guests are expected to conduct themselves in a respectful and considerate manner towards other guests and staff.</div>
         </div>
     </div>
     <br />

     <div >
         <div className='hbugfdxfgh'><span className='hehgcgh'>7. Liability</span>  
         <div className='hbugfdxfgh'>- The West Wind is not liable for any loss, theft, or damage to personal belongings or vehicles during your stay.</div>
         </div>
     </div>
     <br />

     <div >
         <div className='hbugfdxfgh'><span className='hehgcgh'>8. Privacy</span>
         <div className='hbugfdxfgh'>- We respect your privacy.Please refer to our Privacy Policy for more information.</div>
         </div>
     </div>
     <br />

     <div >
         <div className='hbugfdxfgh'><span className='hehgcgh'>9.Jurisdiction</span>
          <div className='hbugfdxfgh'>- Any disputes will be subject to the jurisdiction of the courts in Ooty, The Nilgiris.</div>
          </div>
     </div>
     <br />

     <div >
         <div className='hbugfdxfgh'><span className='hehgcgh'>10. Changes to Terms</span>
         <div className='hbugfdxfgh'>- The West Wind reserves the right to update and modify these terms and conditions at any time without prior notice.</div>
         </div>
     </div>


     </div>
     </div>
     <br />
     <br />
   </div>
   <Footer />
    </>
  )
}

export default Termsandcondition