import React, { useState } from 'react'
import "./About.css"
import axios from 'axios'
import { Base_url } from '../Url';
// import { useNavigate } from 'react-router-dom';
import Footer from '../Footer/Footer';
import Nav from '../Navbar/Nav';
import Transition from '../Transition/Transition';
import { Helmet } from "react-helmet";


function About() {

  const[Name , setName] = useState("")
  const[Email , setemail] = useState("")
  const[Surename , setsurename] = useState("")
  const[Phone , setphone] = useState("")
  const[Subject , setsubject] = useState("")
  const[Message , setmessage] = useState("")

  const conttactinfo =async()=>{
    const result = await axios.post(`${Base_url}/contact-details` , {
      Name : Name,
      Surename:Surename,
      Email : Email,
      Phone:Phone,
      Subject : Subject,
      Message:Message
     
    })

    alert(result.data.msg)
 
  }

  return (
<>
<Helmet>
<meta charSet="utf-8" />
    <title>Get to Know The West Wind - Your Hosts in Ooty</title>
    <meta
    name="description"
    content="Learn about our passion for hospitality and our commitment to creating unforgettable experiences. Discover the story behind The West Wind and our dedication to making your stay special.  "
  />
      </Helmet>


<Nav />
<div className='hero'>
    <img src='/images/a001.png' className='lan' alt='' />
    </div>

    <div className='herocenter'>
  <div className='container-fluid' >
   
    <div>
 <h3 className='text-light hioioi' data-aos="fade-up" data-aos-duration="2000">The West Wind</h3>
  <div className='text-light abhghbhg' data-aos="fade-up" data-aos-duration="2000">Immersion, Seclusion and adventure await
  </div>
  </div>
  
  </div>


</div>

<div className='container-fluid aboutcontent'   >
    <br />
    <br />
    <div className='container d-flex justify-content-center'>
    <div className='headingabout'>
        <h4 data-aos="fade-up" data-aos-duration="2000">Indulege in Serenity at The West Wind- A Hilltop Bed & Breakfast Retreat in Tranquil Ooty</h4>
    </div>
    </div>
    <br />
    <div className='container headtwo' data-aos="fade-up" data-aos-duration="2000">
    Nestled atop the picturesque hills of Ooty, The West Wind invites you to experience a getaway like no other. A haven of peace and tranquility, our charming Bed & Breakfast is your gateway to a world of serenity. 
   <br /> <br />As you step into The West Wind, you'll be greeted by the gentle rustling of leaves and the refreshing scent of nature. Perched on a hilltop, our retreat offers breathtaking panoramic views of Ooty's lush landscapes, inviting you to unwind and reconnect with the beauty of the natural world. 
   <br /><br />At The West Wind, we understand the value of quietude. Our meticulously designed rooms provide a cozy haven where you can escape the hustle and bustle of everyday life. Let the soothing ambiance lull you into a restful slumber, and wake up to the melodies of birdsong, a testament to the untouched beauty that surrounds us. 
   <br /><br />Immerse yourself in the tranquility of our gardens, where you can take leisurely strolls or simply bask in the sunlight with a good book in hand. Indulge in moments of reflection as you gaze upon the sprawling hills and the distant mist that shrouds them, a scene that invokes a sense of profound peace.
   <br /><br />As the sun sets, savor delectable local cuisine prepared with love and care by our chefs. Our dining area provides a panoramic backdrop of the Ooty valley, offering an unforgettable dining experience that complements the delicious flavors of our dishes.
    Whether you're seeking a romantic escape, a rejuvenating solo retreat, or a tranquil family getaway, The West Wind welcomes you with open arms. Let the hilltop breeze carry away your worries, and as the day ends, succumb to the embrace of silence that wraps around you. Experience The West Wind – where every moment is an invitation to sleep soundly, breathe deeply, and embrace the serenity that only nature can provide.

    </div>
<br />
<br />
<div className='container d-flex justify-content-center amyubjh'>Amentities</div>
<br />
    <div className='container headtwo '>
        <div>
            <div className='w' data-aos="fade-up" data-aos-duration="2000">Welcome Platter</div>
            <hr data-aos="fade-up" data-aos-duration="2000" />
            <div className='w' data-aos="fade-up" data-aos-duration="2000">Free Parking</div>
            <hr  data-aos="fade-up" data-aos-duration="2000"/>
            <div className='w' data-aos="fade-up" data-aos-duration="2000">Common Fire Place</div>
            <hr data-aos="fade-up" data-aos-duration="2000" />
            <div className='w' data-aos="fade-up" data-aos-duration="2000">Security Camera</div>
            <hr data-aos="fade-up" data-aos-duration="2000" />
            <div className='w' data-aos="fade-up" data-aos-duration="2000">Back-up Power Supply 24x7</div>
            <hr data-aos="fade-up" data-aos-duration="2000"/>
            <div className='w' data-aos="fade-up" data-aos-duration="2000">Organise Events</div>
            <hr data-aos="fade-up" data-aos-duration="2000"/>

        </div>

        <div>
        <div className='w' data-aos="fade-up" data-aos-duration="2000">Mountain View / Forest View</div>
        <hr data-aos="fade-up" data-aos-duration="2000"/>
            <div className='w' data-aos="fade-up" data-aos-duration="2000">Bedroom with king size bed</div>
            <hr data-aos="fade-up" data-aos-duration="2000"/>
            <div className='w' data-aos="fade-up" data-aos-duration="2000">Common Dining Hall</div>
            <hr data-aos="fade-up" data-aos-duration="2000"/>
            <div className='w' data-aos="fade-up" data-aos-duration="2000">Wi-Fi / internet access / Television</div>
            <hr data-aos="fade-up" data-aos-duration="2000"/>
            <div className='w' data-aos="fade-up" data-aos-duration="2000">Breakfast / Dinner (On Request and Fixed Menu)</div>
            <hr data-aos="fade-up" data-aos-duration="2000"/>
            <div className='w' data-aos="fade-up" data-aos-duration="2000">Kettle Available</div>
            <hr data-aos="fade-up" data-aos-duration="2000" />

        <br />
        <br />
        <br />


        </div>
    </div>
</div>

<div className='loorempopo'>
    <div className='loremTwo'>
        <div className='loremthree'>
            <img src='/images/a50.png' alt='' data-aos="fade-up" data-aos-duration="2000" />
        </div>
        <div className='lorefive'>
            <h4 data-aos="fade-up" data-aos-duration="2000">Seeking a truly peaceful
                 experience? Indulge, escape and
                  relax at The West Wind
</h4>

        </div>
    </div>
    
</div>

<div className='aboutcontent'>
    <div className='container d-flex justify-content-center jiojio'> 
    
  <div className='mt-5 nhjnhjnhj' data-aos="fade-up" data-aos-duration="2000">  From glorious weddings to black-tie galas, The
     West Wind has splendid private event spaces to
      suit every occasion.</div>
    </div>
</div>

<div className='loorempopo'>
    <div className='loremTwo mt-5'>
        <div className='loremthreopop'>
            <img alt='' src='/images/semi4.png' data-aos="fade-up" data-aos-duration="2000" />
        </div>
        <div className='expolpo'>
            <div>
            <div className='seminar' data-aos="fade-up" data-aos-duration="2000">Seminars</div>
            <br />
            <div className='seminarexplore' data-aos="fade-up" data-aos-duration="2000">Explore our array of thoughtfully designed rooms,<br />
                 each tailored to offer comfort and a touch of luxury,<br />
                  whether you're traveling with family, your loved <br />
                  one, or for business.</div>
                   <br />
<div className='exquire' data-aos="fade-up" data-aos-duration="2000">Enquire Now</div>
        </div>
        </div>

        
    </div>
    
</div>

<div className='aboutcontentpopo'>
    <div className='loploplop'> 
  <div className='linepopo'>

  </div>
    </div>
</div>

<div className='loorempopo'>
    <div className='loremTwo mt-5'>

    <div className='expolpopo'>
        <div>
            <div className='seminar' data-aos="fade-up" data-aos-duration="2000">Weddings</div>
            <br />
            <div className='seminarexplore' data-aos="fade-up" data-aos-duration="2000">Explore our array of thoughtfully designed rooms,<br />
                 each tailored to offer comfort and a touch of luxury,<br />
                  whether you're traveling with family, your loved <br />
                  one, or for business.</div>
                   <br />
<div className='exquire' data-aos="fade-up" data-aos-duration="2000">Enquire Now</div>
</div>
        </div>

        <div className='loremthreotwoop'>
            <img  alt='' src='/images/semi2.png' data-aos="fade-up" data-aos-duration="2000" />
        </div>
       

        
    </div>
    
</div>


<div className='koplopop' >
<br />
<br />
<div className='d-flex justify-content-center'>
<div className='lineCOme' >

</div></div>

<div  id='infocontact'>
  <div className='container d-flex justify-content-center'>
 <h1 data-aos="fade-up" data-aos-duration="2000">Contact us to plan<br />your event</h1> 
 </div>
<form>
<div className='container'>
<div className='row' id='formcon'>
  <div className='col-lg-3'></div>
  <div className='col-lg-3'>
  <input type='text' placeholder='Name' value={Name} onChange={(e)=>setName(e.target.value)} data-aos="fade-up" data-aos-duration="2000"/>
    <br />
    <br />
    <input type='text' placeholder='Surename' value={Surename} onChange={(e)=>setsurename(e.target.value)}  data-aos="fade-up" data-aos-duration="2000"/>
    <br />
    <br />
    <input type='text' placeholder='Phone' value={Phone} onChange={(e)=>setphone(e.target.value)}  data-aos="fade-up" data-aos-duration="2000"/>
    <br />
    <br />
    <input type='text' placeholder='Email' value={Email} onChange={(e)=>setemail(e.target.value)} data-aos="fade-up" data-aos-duration="2000"/>
  </div>

  <div className='col-lg-3 subjectone'>
    <input type='text' placeholder='Subject' value={Subject} onChange={(e)=>setsubject(e.target.value)} data-aos="fade-up" data-aos-duration="2000"/>
    <br />
    <br />
    <input type='text' placeholder='Message' value={Message} onChange={(e)=>setmessage(e.target.value)} data-aos="fade-up" data-aos-duration="2000"/>
  </div>
  <div className='col-lg-3'></div>
    
</div>
</div>

</form>


<div className=' container discovercon' data-aos="fade-up" data-aos-duration="2000" onClick={conttactinfo}>
Submit
</div>
<br />
<br />
</div>

  </div>  
<Footer />

</>
  )
}

export default Transition(About)