import React, { useEffect, useRef, useState } from 'react'
import "./RoomList.css"
import {useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios';
import {
  DatePicker,
  useDatePickGetter,
  useDatePickReset,
} from '@bcad1591/react-date-picker';
import Nav from '../Navbar/Nav';
import Footer from '../Footer/Footer';
import Transition from '../Transition/Transition';
import { Base_url } from '../Url';

function RoomList() {
  const { pickedDates } = useDatePickGetter();
  const selectroom = useRef();
  const location = useLocation();
  const [loading , setloading] = useState(true)
  const [adult, setsdult] = useState(0)
  const [adultT, setsdultT] = useState(0)
  const [child, setchils] = useState(0)
  const [childT, setchilsT] = useState(0)
  const [firstdate, setfirstdate] = useState([])
  const [seconddate, seteconddate] = useState("")
  const [night, setnight] = useState("")
  const [filterdataOne, setfilter] = useState([])
  const [filterdata, setData] = useState([])
  const [filterdataT, setDataT] = useState([])
  const [filterdataH, setDataH] = useState([])
  const [filterdataF, setDataF] = useState([])
  const [RoomlistApi, setRoomlistapi] = useState([])
  const [charge, setcharge] = useState([])
  const navigate = useNavigate();
  const resetFunc = useDatePickReset();
  const [datasLast , setDatas] = useState([])
  const RoomlistLastRef = [];
  const datas = [];
  const datasApi = [];
  const dates = [];
  const datesOne = [];
  const initialdate = [];
  const initialdateOne = [];
  initialdate.push(firstdate)
  initialdateOne.push(seconddate)



  const childchagbfe = (e) => {
    setchilsT(e.target.value)
  }
  const selectedcount = (e) => {
    setsdultT(e.target.value)
  }
  const getdata = async () => {
    const datedate = await axios.get(`${Base_url}/list-orders`)
    setcharge(datedate.data)
    
    const datedateOne = await axios.get(`${Base_url}/Room_list`)
    setRoomlistapi(datedateOne.data)
    setfilter(datedateOne.data)
  }

  useEffect(() => {

    const filterData = datasLast.filter((item) => item.RoomType === "DoubleRoom");
    setData(filterData);

    const filterDataT = datasLast.filter((item) => item.RoomType === "KingRoom");
    setDataT(filterDataT);

    const filterDataH = datasLast.filter((item) => item.RoomType === "FamilyRoom");
    setDataH(filterDataH);

    const filterDataF = datasLast.filter((item) => item.RoomType === "SuperiorRoom");
    setDataF(filterDataF);

    getdata()
    if (location.state !== null) {
      setsdult(location.state.adult)
      setchils(location.state.child)
      setfirstdate(location.state.firstdate)
      seteconddate(location.state.seconddate)
      setnight(location.state.total)
      setfirstdate(pickedDates.firstPickedDate?.toString().slice(0, 15));
      seteconddate(pickedDates.secondPickedDate?.toString().slice(0, 15))


    }
    else {

      setfirstdate(pickedDates.firstPickedDate?.toString().slice(0, 15));
      seteconddate(pickedDates.secondPickedDate?.toString().slice(0, 15))
    }
  }, [charge])


  const suryabutton = () => {
    if (location.state !== null) {
      setsdult(location.state.adult)
      setchils(location.state.child)
      console.log("FindDate" ,adult);
    }

setloading(false)

const bookedRoomIds = [];

charge.forEach(item => {
  const {firstdate, seconddate} = item;

  if(initialdate.includes(firstdate)) {
    bookedRoomIds.push(item.Roomid);
  }

  if(initialdateOne.includes(seconddate)) {
    bookedRoomIds.push(item.Roomid);
  }
});

const availableRooms = filterdataOne.filter(room => {
  return !bookedRoomIds.includes(room.Roomid); 
});

setDatas(availableRooms);

  
  }


  const toComponentA = (datasb) => {

    if (pickedDates.firstPickedDate !== null || pickedDates.secondPickedDate !== null) {
      const Night = pickedDates.firstPickedDate.getDate()
      const NightT = pickedDates.secondPickedDate.getDate()

      const totalnight = NightT - Night

      const range = totalnight * datasb.Charge
      const taxji = range * 12 / 100

      const totalamount = range + taxji
console.log(adult);
console.log(adultT);
      navigate('/details', { state: { adult: adult,adultT:adultT,childT : childT, child: child, firstdate: firstdate, totalnight: totalnight, seconddate: seconddate, charge: range, Roomname: datasb.RoomName, id: datasb.Roomid, tax: taxji, totalamount: totalamount } });
    }
    else {


      const rangeF = night * datasb.Charge
      const taxji = rangeF * 12 / 100
      const totalamount = rangeF + taxji
console.log(adult);
console.log(adultT);
      navigate('/details', { state: { adult: adult, child: child,childT : childT,   firstdate: firstdate, totalnight: night, seconddate: seconddate, charge: rangeF, Roomname: datasb.RoomName, id: datasb.Roomid, tax: taxji, totalamount: totalamount } });

    }
  }

  return (


    <>
      <Nav />
      <div className='heroR'>
        <img src='/images/Book.png' className='lanR' alt='' />
      </div>
      <div className='herocenterC'>
        <div className='container-fluid'>

          <div className='jhjhdsv'>
            <div className='kjssdd'>
              <div className=' phjonjoj' data-aos="fade-up" data-aos-duration="2000">
                <div className='innerpjioo' data-aos="fade-up" data-aos-duration="2000">

                  <div className='weswindff' data-aos="fade-up" data-aos-duration="2000">The West Wind</div>
                  <div className='jijijiokoj' data-aos="fade-up" data-aos-duration="2000">66/6, Havelock Rd, Police Quarters, Pudumund, Ooty, Tamil Nadu 643001</div><br />
                  <div className='jijijiokoj' data-aos="fade-up" data-aos-duration="2000">+91 523 523 2523</div>

                </div>
              </div>
            </div>
          </div>

        </div>


      </div>

      <div className='coninfo'>
        <div className='coninfotwo'>
          <div className='conOneR'>

            <div className='initialseacrh'>
              <div className='innerinital' >
                <div className='jijbjhvsdk' >Guest</div>
                <div className='hshhjsvadhjb' ><span><select className='huhbjbnasvbdjcdj' value={adult > 0 ? adult : adultT} onChange={selectedcount}>
                  <option>0</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                </select></span> Adults, <span>
                    <select className='huhbjbnasvbdjcdj' value={child > 0 ? child : childT} onChange={childchagbfe}>
                      <option>0</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                    </select></span> Children</div>
              </div>
              <div className='innerinital' >
                <div className='jijbjhvsdk' >Check-In</div>
                <div className='hshhjsvadhjb'><span>{firstdate}</span></div>
              </div>
              <div className='innerinital' >
                <div className='jijbjhvsdk' >Check-out</div>
                <div className='hshhjsvadhjb' ><span>{seconddate}</span></div>
              </div>
            </div>
            <div className='mx-4 mt-2 hghvabdhgs' >Prices shown in Rupees for 1-night stay</div>

            <div className='mt-4 darfrfr'>
              <DatePicker 
                disablePreviousDays
                monthsShown={1}

              />
            </div>

            <div className='clastcal'>
              <div className='inhuijOne'>
                <div className='hghvabdhgs' >From Rupees 6000 for 1 per night</div>
                <div className='hghvabdhgs'>Excluding Taxes & Fees</div>
              </div>
              <br />
              <div className='inhuij' onClick={resetFunc} >CANCEL</div>
              <br />
              <a href='#RoomdList'><div className='inhuijS' onClick={suryabutton}>Search</div></a>

            </div>

            <div>


            </div>
          </div>
          <div className='contwoR'>
            <div>
              <div className='cunstheard'>
                <div className='customtwoinner' >
                  <div className='guestTwo' >Your Stay</div>
                  <br />
                  <div className='d-flex surya'>
                    <div><div className='inhihihT'>Check-In</div><div className='kokokokok'>After 02:00 pm</div></div>
                    <div><div className='inhihihT' >Check-out</div><div className='kokokokok' >Before 11:00 pm</div></div>
                  </div>
                  <br />
                  <div className='kokokokok' >{firstdate} - {seconddate}</div>
                  <div className='kokokokok' ><span>{adult > 0 ?  adult : adultT}</span> Adults<span className='newchild'>{child > 0 ? child : childT}</span> Child</div><br />
                  <div className='d-flex justify-content-between'><div className='kokokokok'>Room’s Charge</div><div className='kokokokok'>9000</div></div>
                  <div className='d-flex justify-content-between'><div className='kokokokok'>Taxes & GST</div><div className='kokokokok' >1800</div></div>
                  <div><hr /></div>
                  <div className='d-flex justify-content-between'><div className='inhihihT' >TOTAL</div><div className='kokokokok'>10800</div></div>
                  <div className='d-flex justify-content-between'><div className='inhihihT' >Payable Amount 50%</div><div className='kokokokok'>5400</div></div>


                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className='uigvjhk'></div>
      <div className='roomlist'>
    
        <div className='roomlistinner' data-aos="fade-up" data-aos-duration="2000" ref={selectroom}>

          <div className='sekiufhv mt-3' id='RoomdList' >Select a Room</div>


          {
           loading ? "Please Choose Date And Click Search Button .........." :  filterdata.map((datasb) => {
              return (
                <>
                  <div className='dromlk mt-2'>Double Room With Patio</div>
                  <br />
                  <div className='roomcollection'>
                    <div className='jokokoko' >
                      <div className='innercollection'>
                        <div className='collectleft'><img src={datasb.RoomPhoto} alt='' /></div>
                        <div className='collectright'>
                          <div className='dromlk' >{datasb.RoomName}</div>
                          <div className='jhgvbhjfcuhbv'>Sleeps 2 | 100m2</div>
                          <br />
                          <div className='rfgbjhb0' >Explore our array of thoughtfully designed rooms, each<br />
                            tailored to offer comfort and a touch of luxury, whether<br />
                            you're traveling with family, your loved one, or for<br />
                            business.</div>
                          <br />
                          <div className='huhuhuh' >Room details</div>
                          <div><hr /></div>

                          <div className='dflexopo'>
                            <div className='huhuhuh'>Rupees {datasb.Charge}
                              <br />per night</div>
                            {/* <Link to="/details"> <div className='selefopo' onClick={()=>{toComponentB()}}>Select</div></Link> */}
                            <a className='selefopo' onClick={() => { toComponentA(datasb) }} >Select</a>

                          </div>
                          <div ClassName='rfgbjhb0' >Excluding Taxes & Fees</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />

                </>
              )
            })
          }


          {
            filterdataT.map((datasb) => {
              return (
                <>
                  <div className='dromlk'>King Room with Forest View</div>
                  <br />
                  <div className='roomcollection'>
                    <div className='jokokoko' >
                      <div className='innercollection'>
                        <div className='collectleft'><img src={datasb.RoomPhoto} alt='' /></div>
                        <div className='collectright'>
                          <div className='dromlk' >{datasb.RoomName}</div>
                          <div className='jhgvbhjfcuhbv'>Sleeps 2 | 100m2</div>
                          <br />
                          <div className='rfgbjhb0' >Explore our array of thoughtfully designed rooms, each<br />
                            tailored to offer comfort and a touch of luxury, whether<br />
                            you're traveling with family, your loved one, or for<br />
                            business.</div>
                          <br />
                          <div className='huhuhuh' >Room details</div>
                          <div><hr /></div>

                          <div className='dflexopo'>
                            <div className='huhuhuh'>Rupees {datasb.Charge}
                              <br />per night</div>
                            {/* <Link to="/details"> <div className='selefopo' onClick={()=>{toComponentB()}}>Select</div></Link> */}
                            <a className='selefopo' onClick={() => { toComponentA(datasb) }} >Select</a>

                          </div>
                          <div ClassName='rfgbjhb0' >Excluding Taxes & Fees</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />

                </>
              )
            })
          }

          {
             filterdataH.map((datasb) => {
              return (
                <>
                  <div className='dromlk'>Family Room</div>
                  <br />
                  <div className='roomcollection'>
                    <div className='jokokoko' >
                      <div className='innercollection'>
                        <div className='collectleft'><img src={datasb.RoomPhoto} alt='' /></div>
                        <div className='collectright'>
                          <div className='dromlk' >{datasb.RoomName}</div>
                          <div className='jhgvbhjfcuhbv'>Sleeps 2 | 100m2</div>
                          <br />
                          <div className='rfgbjhb0' >Explore our array of thoughtfully designed rooms, each<br />
                            tailored to offer comfort and a touch of luxury, whether<br />
                            you're traveling with family, your loved one, or for<br />
                            business.</div>
                          <br />
                          <div className='huhuhuh' >Room details</div>
                          <div><hr /></div>

                          <div className='dflexopo'>
                            <div className='huhuhuh'>Rupees {datasb.Charge}
                              <br />per night</div>
                            {/* <Link to="/details"> <div className='selefopo' onClick={()=>{toComponentB()}}>Select</div></Link> */}
                            <a className='selefopo' onClick={() => { toComponentA(datasb) }} >Select</a>

                          </div>
                          <div ClassName='rfgbjhb0' >Excluding Taxes & Fees</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />

                </>
              )
            })
          }


          {
            firstdate?.toString().slice(4 , 7) === "Apr" && filterdataF.map((datasb) => {
              return (
                <>
                  <div className='dromlk'>Superior Room</div>
                  <br />
                  <div className='roomcollection'>
                    <div className='jokokoko' >
                      <div className='innercollection'>
                        <div className='collectleft'><img src={datasb.RoomPhoto} alt='' /></div>
                        <div className='collectright'>
                          <div className='dromlk' >{datasb.RoomName}</div>
                          <div className='jhgvbhjfcuhbv'>Sleeps 2 | 100m2</div>
                          <br />
                          <div className='rfgbjhb0' >Explore our array of thoughtfully designed rooms, each<br />
                            tailored to offer comfort and a touch of luxury, whether<br />
                            you're traveling with family, your loved one, or for<br />
                            business.</div>
                          <br />
                          <div className='huhuhuh' >Room details</div>
                          <div><hr /></div>

                          <div className='dflexopo'>
                            <div className='huhuhuh'>Rupees {datasb.Charge}
                              <br />per night</div>
                            {/* <Link to="/details"> <div className='selefopo' onClick={()=>{toComponentB()}}>Select</div></Link> */}
                            <a className='selefopo' onClick={() => { toComponentA(datasb) }} >Select</a>

                          </div>
                          <div ClassName='rfgbjhb0' >Excluding Taxes & Fees</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />

                </>
              )
            })
          }


          <br />
          <br />
          <br />
        </div>
      </div>

      <Footer />

    </>
  )
}

export default Transition(RoomList)