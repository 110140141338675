import React, { useState } from 'react'
import "./Home.css"
import {
    DatePicker,
    useDatePickGetter,
  } from '@bcad1591/react-date-picker';
import { Link, useNavigate } from 'react-router-dom';


function Menucal() {
    const { pickedDates } = useDatePickGetter();
    const [count, setcount] = useState(0);
    const [countTwo, setcountTwo] = useState(0);
    const [firstdate, setfirstdate] = useState(0)
    const [seconddate, seteconddate ] = useState(0)
    // const [total , settotal] = useState([]).''

    const navigate = useNavigate();
    const toComponentB=()=>{
        
        if(pickedDates.firstPickedDate !== null || pickedDates.secondPickedDate !== null){
            const Night = pickedDates.firstPickedDate.getDate()
            const NightT = pickedDates.secondPickedDate.getDate()
            const totalnight = NightT - Night 
            navigate('/Booking',{state:{adult:count,child:countTwo , firstdate:firstdate  , seconddate : seconddate , total : totalnight}});
        
        }
    
            }
        
  
const increment =()=>{
    setcount(count+1)
    if(count >= 3){
      alert("Only 3 Members allowed")
    }
    }
    
    const decrement =()=>{
    setcount(count-1)
    }
    
    
    const incrementTwo =()=>{
      setcountTwo(countTwo+1)
      if(countTwo >= 3){
        alert("Only 3 Members allowed")
      }
    
      }
      
      const decrementTwo =()=>{
      setcountTwo(countTwo-1)
      }
  return (
    <>

<div className='nbhgasdVyashj'>
  <div >
    <div  >
      <div className='hgjncvhgv'> 
        
       <Link to="/home"> <button type="button" class=" clopop" ><img src='/images/c5.png' className='closeimgae'  alt=''/></button></Link>
      </div>
      <div class="">
      <div>
        <div className='conjojo'>
        <div className='conhioi' > 
        <div className='d-flex' ><img className='gugu' src='/images/C10.png' alt=''/></div>
        <div className='lpolpo'>Check-In - Check-Out</div> </div>
<div className='davbhjbn'>
<DatePicker className="jhhkhb"
disablePreviousDays 
timepickerfalse
showOneCalendar
/>

</div>
<br />
<br />
<br />
<div className='jokplpk'>

  <div className='d-flex'><div>Adults</div><div className='d-flex marginhi'><div  onClick={decrement} className='circleol marginhi'><img className='mb' src='/images/mb.png' alt='' /></div><div className='marginhi'>0{count}</div><div className='circleol marginhi' onClick={increment} ><img className='mp' alt='' src='/images/mp.png' /></div></div></div>
  <div className='d-flex'><div className='childrjh'>Children</div><div className='d-flex marginhi'><div onClick={decrementTwo}  className='circleol marginhi'><img className='mb' alt='' src='/images/mb.png' /></div><div className='marginhi'>0{countTwo}</div><div className='circleol marginhi' onClick={incrementTwo}><img alt='' className='mp' src='/images/mp.png' /></div></div></div>
</div>
<br />
<br />
<br />
<div className='hujjiji'>
 {/* <a > <div className='jokokok' >Check Availability</div></a> */}
 <a  className='jokokok' onClick={()=>{toComponentB()}} >Check Availability</a>
 <div>
 
 </div>
 
</div>
        </div>
      </div>
      </div>
      
    </div>
  </div>
</div>



    </>
  )
}

export default Menucal