import React from 'react'
import "./Footer.css"

function Footer() {
  return (
    <>
    <footer>
      <div className='footer'>
         <div className='first'>
            <div>
           <h3>Contact</h3>
           <br />
           <a href='+91 523 523 2523'><p>+91 80727 54415/ 63796 79146</p></a>
           <a href='mailto:info@thewestwindooty.com'><p>info@thewestwindooty.com</p></a>
            </div>
           <br />
           <div>
            <h3>The West Wind</h3>
            <p>66/6 Welbeck Avenue,
              <br />
              Havelock road, Ooty,
              <br />
              Tamil Nadu 643001
            </p>
           </div>
           <br />

           <a href='#'> <div>
            <img src='/images/insta.png' className='instagramhgj' alt='' />
           </div></a>
           <br />
         </div>

         <div className='first'>
          <h3>Explore</h3>
          <br />

         <a href='/aboutus'> <p>About</p></a>
         <a href='/rooms'> <p>Rooms & Suites</p></a>
         <a href='/Gallery'> <p>Gallery</p></a>
         </div>

         <div className='first'>
          <h3>News</h3>
          <br />

          <a href='/blog'><p>Blogs</p></a>
         </div>

         <div className='first last'>
          <div>
          <h3>Legal Notice</h3>
          <br />

          <a href='/Termsandcondition'><p>Terms and Conditions</p></a>
          <a href='/Privacypolicy'><p>Privacy Policy</p></a>
          <a href='/RefundPolicy'><p>Refund Policy</p></a>
         </div>
         </div>
         
      </div>
    </footer>
    <div className='lineV'>
</div>

    <div className='lastfooter'>
      <div className='copyright'>


<div>@Copyright The West Wind</div>
<div>2023. All rights reserved</div>
<a className='hgcgj' href='https://www.instagram.com/thecreativhivestudio'><div>Designed and Developed by Aweganyz.</div></a>

</div>
<br />  
<br />
<br />
</div>
    </>
  )
}

export default Footer