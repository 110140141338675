import './App.css';
import { Routes , Route, useLocation} from "react-router-dom"
import Home from './Components/LandingPage/Home';
import Contact from './Components/ContactUs/Contact';
import About from './Components/Aboutus/About';
import Rooms from './Components/Rooms/Rooms';
import Blog from './Components/Rooms/Blog';
import FirstRoom from './Components/Aboutus/FirstRoom';
import SecondRoom from './Components/Aboutus/SecondRoom';
import ThirdRoom from './Components/Aboutus/ThirdRoom';
import FourRoom from './Components/Aboutus/FourRoom';
import Details from './Components/Details/Details';
import RoomList from './Components/RoomList/RoomList';
import Success from './Components/Success/Success';
import Loader from './Components/Loader/Loader';
import Menucal from './Components/LandingPage/Menucal';
import {AnimatePresence} from "framer-motion"
import aos from "aos"
import 'aos/dist/aos.css';
import CancellationPolicy from './Components/CancellationPolicy';
import Privacypolicy from './Components/Privacypolicy';
import Termsandcondition from './Components/Termsandcondition';
import Gallery from './Components/Gallery';
import ErrorOne from './ErrorOne';
import {Cloudinary} from "@cloudinary/url-gen";

aos.init()
function App() {
  const cld = new Cloudinary({cloud: {cloudName: 'dygcihxqu'}});
  const location = useLocation() 
  return (
  <>
  
  <AnimatePresence mode='wait'>

<Routes location={location} key={location.pathname}>
<Route index element={<Loader />}></Route>
  <Route path='/home' element={<Home />}></Route>
  <Route path='/contactus' element={<Contact />}></Route>
  <Route path='/aboutus' element={<About />}></Route>
  <Route path='/datas' element={<Menucal />}></Route>
  <Route path='/rooms' element={<Rooms />}></Route>
  <Route path='/blog' element={<Blog />}></Route>
  <Route path='/details' element={<Details />}></Route>
  <Route path='/Booking' element={<RoomList />}></Route>
  <Route path='/DoubleRoomWithPatio' element={<FirstRoom />}></Route>
  <Route path='/KingRoomWithForestView' element={<SecondRoom />}></Route>
  <Route path='/FamilyRoom' element={<ThirdRoom />}></Route>
  <Route path='/SuperiorRoom' element={<FourRoom />}></Route>
  <Route path='/success' element={<Success />}></Route>
  <Route path='/RefundPolicy' element={<CancellationPolicy />}></Route>
  <Route path='/Privacypolicy' element={<Privacypolicy />}></Route>
  <Route path='/Termsandcondition' element={<Termsandcondition />}></Route>
  <Route path='/Gallery' element={<Gallery />}></Route>
  <Route path='*' element={<ErrorOne />}></Route>
</Routes>

</AnimatePresence>
  </>
  );
}

export default App;
