import React from 'react'
import "./Rooms.css"
// import { Link } from 'react-router-dom'
import Footer from '../Footer/Footer'
import Nav from '../Navbar/Nav'
import Transition from '../Transition/Transition'

function Blog() {
  return (
    <>
<Nav />
<div className='hero'>
    <img src='/images/blog.png' className='lan' alt='' />
    </div>

    <div className='herocenter'>
  <div className='container-fluid' >
   
    <div>
 <h3 className='text-light jjijij' data-aos="fade-up" data-aos-duration="2000">Blogs</h3>
  <div className='text-light jijijtw' data-aos="fade-up" data-aos-duration="2000">Warmth. Care. Peace
  </div>
  </div>
  
  </div>


</div>



<div className='roomespop'>
    <div className='roomsinner'>
<div className='OneRoom'>

        <img src='/images/M1.png' alt='' data-aos="fade-up" data-aos-duration="2000" />
   

        <div>
          <br />
          <div className='headrommnam' data-aos="fade-up" data-aos-duration="2000">Lorem Ipsum Dolor sit</div>
      {/* <div className='hnjhnhn' data-aos="fade-up" data-aos-duration="2000"><span>100m2</span>/ Mountain view / 2 Adults 1 Child</div> */}
<br />
      <div className='exploihbhjn' data-aos="fade-up" data-aos-duration="2000">Explore our array of thoughtfully designed rooms,<br />
         each tailored to offer comfort and a touch of luxury,<br />
          whether you're traveling with family, your loved one, or<br />
           for business.</div>
<br />
<div className='dopopo'>
     <a href="/DoubleRoomWithPatio"> <div className='dicoverrromm' data-aos="fade-up" data-aos-duration="2000"> Discover More</div></a>
    </div>
    </div>
</div>



<div className='TwoRoom'>

<img src='/images/M1.png' alt='' data-aos="fade-up" data-aos-duration="2000"  />
<div>
<br />
      <div className='headrommnam' data-aos="fade-up" data-aos-duration="2000">Lorem Ipsum Dolor sit</div>
      {/* <div className='hnjhnhn' data-aos="fade-up" data-aos-duration="2000"><span>100m2</span>/ Mountain view / 2 Adults 1 Child</div> */}
<br />
      <div className='exploihbhjn' data-aos="fade-up" data-aos-duration="2000">Explore our array of thoughtfully designed rooms,<br />
         each tailored to offer comfort and a touch of luxury,<br />
          whether you're traveling with family, your loved one, or<br />
           for business.</div>
<br />
<div className='dopopo'>
<a href="/KingRoomWithForestView"> <div className='dicoverrromm' data-aos="fade-up" data-aos-duration="2000"> Discover More</div></a>
    </div>
    </div>
</div>
    </div>
</div>

<div className='roomespop'>
    <div className='roomsinner'>
<div className='OneRoom'>

        <img src='/images/M1.png' alt=''  data-aos="fade-up" data-aos-duration="2000"/>
        <div>
<br />
      <div className='headrommnam' data-aos="fade-up" data-aos-duration="2000">Lorem Ipsum Dolor sit</div>
      {/* <div className='hnjhnhn' data-aos="fade-up" data-aos-duration="2000"><span>100m2</span>/ Mountain view / 2 Adults 1 Child</div> */}
<br />
      <div className='exploihbhjn' data-aos="fade-up" data-aos-duration="2000">Explore our array of thoughtfully designed rooms,<br />
         each tailored to offer comfort and a touch of luxury,<br />
          whether you're traveling with family, your loved one, or<br />
           for business.</div>
<br />
<div className='dopopo'>
<a href="/FamilyRoom"> <div className='dicoverrromm' data-aos="fade-up" data-aos-duration="2000"> Discover More</div></a>
    </div>
    </div>
    
</div>
<div className='TwoRoom'>

<img src='/images/M1.png' alt='' data-aos="fade-up" data-aos-duration="2000" />
<div>
<br />
      <div className='headrommnam' data-aos="fade-up" data-aos-duration="2000">Lorem Ipsum Dolor sit</div>
      {/* <div className='hnjhnhn' data-aos="fade-up" data-aos-duration="2000"><span>100m2</span>/ Mountain view / 2 Adults 1 Child</div> */}
<br />
      <div className='exploihbhjn' data-aos="fade-up" data-aos-duration="2000">Explore our array of thoughtfully designed rooms,<br />
         each tailored to offer comfort and a touch of luxury,<br />
          whether you're traveling with family, your loved one, or<br />
           for business.</div>
<br />
<div className='dopopo'>
<a href="/SuperiorRoom"> <div className='dicoverrromm' data-aos="fade-up" data-aos-duration="2000"> Discover More</div></a>
      </div>
    </div>
<br />
<br />
</div>
    </div>
</div>
<Footer />
    </>
  )
}

export default Transition(Blog)