import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { DatePickerProvider  } from '@bcad1591/react-date-picker';
import {BrowserRouter as Router , Routes , Route} from "react-router-dom"


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <DatePickerProvider>
      <Router>
    <Routes>
      <Route path='/*' element={<App />}></Route>
    </Routes>
    </Router>
    </DatePickerProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
