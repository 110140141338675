import React from 'react'
import "./Loader.css"

function Loader() {
  return (
    <>
    <div className='loader'>
          <video  
          muted
          autoPlay={"autoplay"}
          preLoad="auto"
          // loop
          >
                   <source src='/images/loader1.mp4' type="video/mp4" />

            </video> 

    </div>
    <div className='explorenoew'>
      <div className='jijijiji'>
        <a href='/home'><div className='huhuhuhuhkok'>EXPLORE NOW</div></a>
        </div>
    </div>
    </>
  )
}

export default Loader