import React, { useState } from 'react'
import "./Contact.css"
import axios from 'axios'
import { Base_url } from '../Url';
// import { useNavigate } from 'react-router-dom';
import Nav from '../Navbar/Nav';
import Footer from '../Footer/Footer';
import Transition from '../Transition/Transition';
import { Helmet } from "react-helmet";


function Contact() {
  // const navigate = useNavigate()

  const[Name , setName] = useState("")
  const[Email , setemail] = useState("")
  const[Surename , setsurename] = useState("")
  const[Phone , setphone] = useState("")
  const[Subject , setsubject] = useState("")
  const[Message , setmessage] = useState("")

  const conttactinfo =async()=>{
    const result = await axios.post(`${Base_url}/contact-details` , {
      Name : Name,
      Surename:Surename,
      Email : Email,
      Phone:Phone,
      Subject : Subject,
      Message:Message
     
    })

    alert(result.data.msg)
 
  }

  return (
   <>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Connect with The West Wind - Reach Out to Us</title>
              <meta
    name="description"
    content="Have a question or want to make a reservation? We'd love to hear from you. Get in touch with The West Wind team and let us assist you in planning your stay in Ooty."
  />
            </Helmet>
   <Nav />
 <div className='heroC'>
    <img src='/images/Ln.jpg' className='lanC' alt='' />
    </div>
    <div className='herocenterC'>
  <div className='container-fluid'>
   
    <div>
  <h3 className=' jjijij' data-aos="fade-up" data-aos-duration="2000">Get in Touch</h3>
  <div className=' jijijtw' data-aos="fade-up" data-aos-duration="2000">Connect with The West Wind, your serene Ooty retreat. Inquire now!
  </div>
  </div>
  
  </div>

</div>
{/* <br /> */}
<div className='coninfo'>
  <div className='coninfotwo'>
    <div className='conOne'>
      <h3 data-aos="fade-up" data-aos-duration="2000">Contact Information</h3>
      <br />
<hr className='hari' data-aos="fade-up" data-aos-duration="2000" />
<br />

<div className='conadd'>
  <h3 data-aos="fade-up" data-aos-duration="2000">Address</h3>
  {/* <br /> */}
  <div className='jkjkjljkjh' data-aos="fade-up" data-aos-duration="2000"> The West Wind <br />66/6 & 66/7, Welbeck<br />Avenue, Havelock Rd, Ooty,<br />Tamil Nadu 643001</div>
</div>

<div className='conadd'>
  <h3 data-aos="fade-up" data-aos-duration="2000">Phone</h3>
  <div className='jkjkjljkjh ujuju' data-aos="fade-up" data-aos-duration="2000"><a href="tel:+91 125 256 256"> +91 80727 54415</a></div>
</div>

<div className='conadd'>
  <h3 data-aos="fade-up" data-aos-duration="2000">Email</h3>
  <div className='ujuju' data-aos="fade-up" data-aos-duration="2000"><a href='info@thewestwindooty.com'>info@thewestwindooty.com</a></div>
</div>
<br />
<br />
<br />
<br />
<br />
<div>


</div>
    </div>
    <div className='contwo' >
    <iframe title='map'  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3910.8515571281946!2d76.69097217687225!3d11.418318447022408!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8bd6e1fb739a1%3A0x499abf2d6e80f05a!2sThe%20West%20Wind!5e0!3m2!1sen!2sin!4v1693991426700!5m2!1sen!2sin" width="100%" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  </div>

</div>


<div className='koplopop'>
<br />
<div className='hjcvjgvh'>
<div className='lineCONe' data-aos="fade-up" data-aos-duration="2000">

</div>
</div>


<div className='infocontactjhgb'  id='infocontact'>
  <div className='container d-flex justify-content-center'>
 <h1 data-aos="fade-up" data-aos-duration="2000">Contact us to Enquire</h1> 
 </div>
<form>
<div className='container'>
<div className='row' id='formcon'>
  <div className='col-lg-3'></div>
  <div className='col-lg-3' id='kjhblk'>
    <input type='text' placeholder='Name' value={Name} onChange={(e)=>setName(e.target.value)} data-aos="fade-up" data-aos-duration="2000"/>
    <br />
    <br />
    <input type='text' placeholder='Surename' value={Surename} onChange={(e)=>setsurename(e.target.value)} data-aos="fade-up" data-aos-duration="2000" />
    <br />
    <br />
    <input type='text' placeholder='Phone' value={Phone} onChange={(e)=>setphone(e.target.value)} data-aos="fade-up" data-aos-duration="2000"/>
    <br />
    <br />
    <input type='text' placeholder='Email' value={Email} onChange={(e)=>setemail(e.target.value)} data-aos="fade-up" data-aos-duration="2000"/>
  </div>

  <div className='col-lg-3 subjectone'>
    <input type='text' placeholder='Subject' value={Subject} onChange={(e)=>setsubject(e.target.value)} data-aos="fade-up" data-aos-duration="2000"/>
    <br />
    <br />
    <input type='text' placeholder='Message' value={Message} onChange={(e)=>setmessage(e.target.value)} data-aos="fade-up" data-aos-duration="2000" />
  </div>
  <div className='col-lg-3'></div>
    
</div>  
</div>

</form>


<div className=' container discovercon' onClick={conttactinfo} data-aos="fade-up" data-aos-duration="2000">
  Discover More
</div>
<br />
<br />
</div>

  </div>  


<Footer />
   </>
  )
}

export default Transition(Contact)