import React, { useState } from 'react'
import {Link, useNavigate} from "react-router-dom"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './Home.css';
import { Helmet } from "react-helmet";

import Nav from '../Navbar/Nav';
import Footer from '../Footer/Footer';
// import Transition from '../Transition/Transition';



function Home() {
  // const { pickedDates } = useDatePickGetter();
  const [count, setcount] = useState(0);
  const [countTwo, setcountTwo] = useState(0);


const navigate = useNavigate();
    
    const toComponentA = ()=>{
      if(count > 0 && countTwo > 0 ){
        navigate('/Booking',{state:{adult:count,child:countTwo}});
      }
      else{
        navigate('/Booking')
      }

    }

const increment =()=>{
setcount(count+1)
if(count >= 3){
  alert("Only 3 Members allowed")
}
}

const decrement =()=>{
setcount(count-1)
}


const incrementTwo =()=>{
  setcountTwo(countTwo+1)
  if(countTwo >= 3){
    alert("Only 3 Members allowed")
  }

  }
  
  const decrementTwo =()=>{
  setcountTwo(countTwo-1)
  }

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1900 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 1900, min: 1438 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1438, min: 1074 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 1074, min: 714 },
      items: 2
    },
    iphone: {
      breakpoint: { max: 714, min: 0 },
      items: 1
    }
  };


  return (
    < >
       <Helmet>
                <meta charSet="utf-8" />
                <title>The West Wind - Your Perfect Retreat in Ooty | Best Hotel & Bed & Breakfast in Ooty</title>
              <meta
    name="description"
    content=" Experience the warmth and comfort of The West Wind, your perfect getaway in Ooty. Discover inviting accommodations, breathtaking views, and exceptional hospitality.
    "
  />
            </Helmet>
    <Nav  />

    <div className='hero ' id='jhgbjhkb' data-aos="fade-up" data-aos-duration="2000">
    <img src='/images/lan001.png' className='lan' id="heromobtwo" alt='' />
    <img src='/images/mme.png' className='lan' id="heromob" alt='' />
    </div>

    <div className='herocenter'>
  <div className='container-fluid' >
   
    <div>
 <h3 className='text-light' id='headinghero'  data-aos="fade-up" data-aos-duration="2000">Escape to Tranquil Bliss at The West Wing</h3>
  <div className='text-light' id='headsub'  data-aos="fade-up" data-aos-duration="2000">Discover an enchanting getaway in the hilltops of Ooty. Immerce youself in nature's
    <br />
    embrace
  </div>

  </div>
  
  </div>


</div>


<div className='lastnhome text-light' data-aos="fade-up" data-aos-duration="2000">
  <div className='kokoko'>
      <div className='One' >
      <Link to="/datas"><div className='d-flex' > <div><img className='toparr' src='/images/C1.png' alt=''/></div><div className='datecheck'>Check-In - Check-Out</div></div></Link>
      <Link to="/datas"> <div className='rihhtjkbn' ><div ><img className='toparr' src='/images/t1.png' alt='' /></div></div></Link>
      </div>
      <div className='nininin'>
      
      </div>
      <div className='two'>
      <div className='d-flex'> 
       <div className='datecheckTwo'>Adults</div>
       <div className='d-flex'><div onClick={decrement} className='circleo marginhi'><img className='minus' src='/images/mi.png'  alt='' /></div><div className='count marginhi'>0{count}</div><div onClick={increment} className='circle marginhi'><img src='/images/pl.png' alt='' /></div></div>
       <div></div>
       <div className='datecheckTwo childrjh'>Children</div>
       <div className='d-flex'><div onClick={decrementTwo} className='circleo marginhi'><img className='minus' src='/images/mi.png' alt=''/></div><div className='count marginhi'>0{countTwo}</div><div onClick={incrementTwo} className='circle marginhi'><img src='/images/pl.png'  alt=''/></div></div>
       </div>
      {/* <Link to="/roomlist">  <div><div ><img className='toparrright' src='/images/s1.png' /></div></div></Link> */}

      <div><a   onClick={()=>{toComponentA()}}><div ><img className='toparrright' src='/images/s1.png' alt='' /></div></a></div>

      </div>
  </div>
</div>


<div id='hometwo'>

<main >
<div className='container d-flex justify-content-center' id='hometwosub' >
  <div className='Textpopo'>
  <img data-aos="fade-up" alt='' data-aos-duration="2000" src='/images/Logo1.png' className='iopop' />
  
  <div className='exploiij' data-aos="fade-up" data-aos-duration="2000">Explore our array of thoughfully designed rooms, each tailored to offer comfort and a
    <br />
    touch of luxury, whether you're traveling with family, your loved one, or for business
  </div>
  </div>
</div>



<div className='Maincontent mt-5'>
  <div className='maintwo'>
    <div className='popl'>
    <div>
    <div className='leftOne'><img src='/images/H1.png' alt='' data-aos="fade-up" data-aos-duration="2000"/></div>
  <div className='textimage' data-aos="fade-up" data-aos-duration="2000"> Step into our welcoming embrace as you enter The West Wind. Our attentive staff is ready to make your stay extraordinary.
  </div>
    </div>
    <div>
      <div className='lefttwo'>
    <img src='/images/td1.png' data-aos="fade-up " alt='' data-aos-duration="2000" /></div>
  <div className='textimage' data-aos="fade-up" data-aos-duration="2000"> 
  Come, discover a slice of paradise at The<br /> West Wind, Ooty

  </div>
    </div>
    </div>
    <div className='mainop popl'>
    <div>
  <div className='hrff' data-aos="fade-up" data-aos-duration="2000"></div>
  <div className='spacetect' data-aos="fade-up" data-aos-duration="2000">Find Your Perfect Retreat</div>
  <div className='hrff' data-aos="fade-up" data-aos-duration="2000"></div>
  <div className='spacetect' data-aos="fade-up" data-aos-duration="2000">Exquisitely designed rooms far from 
    <br />the disturbances of everyday life</div>
  <div className='hrffpo' data-aos="fade-up" data-aos-duration="2000"></div>
  
  </div>
    </div>
    <div className='popl mt-4 d-flex justify-content-end'>
       <div className='hololo' >
        <div className='rigthimahge'>
       <img src='/images/x2.png' alt='' data-aos="fade-up" data-aos-duration="2000"/></div>
  <div className='gatwary' data-aos="fade-up" data-aos-duration="2000">Your gateway to serenity. The moment you <br />cross our threshold, you leave behind the<br /> world's chaos.
  <div className='righttwo'>
        <img src='/images/H6.png' alt='' data-aos="fade-up" data-aos-duration="2000" />
       </div>
  </div>
       </div>
       
    </div>
  </div>
</div>
  
<div className='d-flex justify-content-center spacetect'  >
  <div id='mobileminbu'>
<div className='middleimage'>
  <img src='/images/h3.png' alt='' data-aos="fade-up" data-aos-duration="2000" />
  <div className='middleimagetext' data-aos="fade-up" data-aos-duration="2000">
  Witness the enchanting Ooty sunset from our hillside vantage point. A sight to behold,<br /> a memory to cherish.
  </div>
  </div>
</div>
</div>


<div className='thirrdhomehero'>

  <div className='thirdhome'>
    <div className='guonbj'>
      <h3 className='discoverkop' data-aos="fade-up" data-aos-duration="2000">Discover Tranquility at The West Wind:Your Hilltop Bed&Breakfast in Peaceful Ooty</h3>
    <br />
    <br /> 
    <div className='rooms' data-aos="fade-up" data-aos-duration="2000">Rooms&Suites</div>
    <br />
    <div className='sleep' data-aos="fade-up" data-aos-duration="2000">Sleep at The West Wind... Enjoy the silence.</div>
    <br />
    <div className='sleep' data-aos="fade-up" data-aos-duration="2000">Perched atop the serene hills of Ooty, The West Wind offers a heaven of
      <br/>calm that beckons you. Our charming Bed & Breakfast is your escape to serenity
    </div>
    <br />

    <div className='sleep' data-aos="fade-up" data-aos-duration="2000"> Inhale the crisp mountain air as you arrive. Our hilltop retreat presents
    <br /> stunning panoramic views of Ooty's lush landscapes, inviting you to <br />unwind and rejuvenate.
    </div>
    <br />

    <a href="/rooms"><div className='discover' data-aos="fade-up" data-aos-duration="2000">
      Discover All Rooms & Suites
    </div></a>
    <br />
    <br />
    
    </div>
    <div className='sidemobep'>
 <div className='jhkgb'>
      <img src='/images/td2.png'  data-aos="fade-up" data-aos-duration="2000" className='lakpkp' alt='Surya' />
      </div>
    </div>
    
  </div>


  
</div>

<div className='thirrdhomeherogal'>
  <div className='galhero'>
      <div className='Galpopo OneList' data-aos="fade-up" data-aos-duration="2000">Double Room with Patio</div>
      <div className='Galpopo Twolist' data-aos="fade-up" data-aos-duration="2000">King Room with Forest View</div>
      <div className='Galpopo family Threelist' data-aos="fade-up" data-aos-duration="2000">Family Room</div>
      <div className='Galpopo family Fourlist' data-aos="fade-up" data-aos-duration="2000">Superior Room</div>
  </div>
</div>
<div className='extra'></div>


<div className='GalleryHome'>
<div className='GalleryHometwo'>
  <div>
    <h3 className='hujnuhjibjhk' data-aos="fade-up" data-aos-duration="2000">Gallery</h3>
    {/* <p className='wutnesdd' data-aos="fade-up" data-aos-duration="2000">Witness the enchanting Ooty sunset from our hillside vantage point. A sight to <br />behold, a memory to
       cherish.
    </p> */}
    <div className='koplop'>
   {/* <a href="/rooms"> <p className='discoverTwo' data-aos="fade-up" data-aos-duration="2000">
 Discover All Rooms & Suites
    </p></a> */}
    </div>
  </div>

</div>

</div>



 <Carousel responsive={responsive} className='kopkopkopkop'>
  <div className='imagelopT'>
    <img src='/images/x1.png' data-aos="fade-up" data-aos-duration="2000" alt='' />
  </div>
  <div className='imagelop'>
  <img src='/images/x2.png'  data-aos="fade-up" data-aos-duration="2000" alt=''/>
  </div>
  <div className='imagelopT'>
  <img src='/images/x3.png' data-aos="fade-up" data-aos-duration="2000" alt='' />
  </div>
  <div className='imagelop'>
  <img src='/images/x4.png' data-aos="fade-up" data-aos-duration="2000" alt='' />
  </div>
  <div className='imagelopT'>
  <img src='/images/x5.png'  data-aos="fade-up" data-aos-duration="2000" alt=''/>
  </div>
  <div className='imagelop'>
  <img src='/images/x6.png'  data-aos="fade-up" data-aos-duration="2000" alt=''/>
  </div>
  <div className='imagelopT'>
  <img src='/images/x7.png' data-aos="fade-up" data-aos-duration="2000"  alt=''/>
  </div>

  <div className='imagelop'>
  <img src='/images/x8.png'  data-aos="fade-up" data-aos-duration="2000" alt=''/>
  </div>
  <div className='imagelopT'>
  <img src='/images/x9.png' data-aos="fade-up" data-aos-duration="2000"  alt=''/>
  </div>
  <div className='imagelop'>
  <img src='/images/x10.png'  data-aos="fade-up" data-aos-duration="2000" alt=''/>
  </div>
  <div className='imagelopT'>
  <img src='/images/x11.png' data-aos="fade-up" data-aos-duration="2000" alt='' />
  </div>
  <div className='imagelop'>
  <img src='/images/x123.png'  data-aos="fade-up" data-aos-duration="2000" alt=''/>
  </div>
  <div className='imagelopT'>
  <img src='/images/x13.png' data-aos="fade-up" data-aos-duration="2000" alt='' />
  </div>
  <div className='imagelop'>
  <img src='/images/x14.png'  data-aos="fade-up" data-aos-duration="2000" alt=''/>
  </div>
  <div className='imagelopT'>
  <img src='/images/x15.png' data-aos="fade-up" data-aos-duration="2000" alt='' />
  </div>
  <div className='imagelop'>
  <img src='/images/x16.png'  data-aos="fade-up" data-aos-duration="2000" alt=''/>
  </div>
  <div className='imagelopT'>
  <img src='/images/x17.png' data-aos="fade-up" data-aos-duration="2000" alt=''/>
  </div>
  <div className='imagelop'>
  <img src='/images/x18.png'  data-aos="fade-up" data-aos-duration="2000" alt=''/>
  </div>
  <div className='imagelopT'>
  <img src='/images/x19.png' data-aos="fade-up" data-aos-duration="2000" alt='' />
  </div>
  <div className='imagelop'>
  <img src='/images/x20.png'  data-aos="fade-up" data-aos-duration="2000" alt=''/>
  </div>
  <div className='imagelopT'>
  <img src='/images/x21.png' data-aos="fade-up" data-aos-duration="2000" alt='' />
  </div>
  <div className='imagelop'>
  <img src='/images/x22.png'  data-aos="fade-up" data-aos-duration="2000" alt=''/>
  </div>
  <div className='imagelopT'>
  <img src='/images/x23.png' data-aos="fade-up" data-aos-duration="2000" alt=''/>
  </div><div className='imagelopT'>
  <img src='/images/x24.png'  data-aos="fade-up" data-aos-duration="2000" alt=''/>
  </div>


  
</Carousel>;

<br />
<br />
<br />
<br />

</main>



</div>
<Footer />
    </ >
  )
}

export default Home 