import React, { useState } from 'react'
import "./About.css"
// import { Link } from 'react-router-dom'
import axios from 'axios'
import { Base_url } from '../Url';
// import { useNavigate } from 'react-router-dom';
import Nav from '../Navbar/Nav';
import Footer from '../Footer/Footer';
import Transition from '../Transition/Transition';

function ThirdRoom() {

  // const navigate = useNavigate()

  const[Name , setName] = useState("")
  const[Email , setemail] = useState("")
  const[Surename , setsurename] = useState("")
  const[Phone , setphone] = useState("")
  const[Subject , setsubject] = useState("")
  const[Message , setmessage] = useState("")

  const conttactinfo =async()=>{
    const result = await axios.post(`${Base_url}/contact-details` , {
      Name : Name,
      Surename:Surename,
      Email : Email,
      Phone:Phone,
      Subject : Subject,
      Message:Message
     
    })

    alert(result.data.msg)
 
  }


  return (
    <>
    <Nav />
    <div className='hero'>
        <img src='/images/four.png' className='lan' alt='' />
        </div>
    
        <div className='herocenter'>
      <div className='container-fluid' >
       
        <div>
     <h3 className='text-light kdhhjbsfbw'>Family Room</h3>
      <div className='text-light ukyjgubikhgb'>Enjoy a Family Room, offering amazing forest and landscape views.
      </div>
      </div>
      
      </div>
    
    
    </div>
    
    <div className='container-fluid aboutcontent'   >
        <br />
        <br />
        <div className='container d-flex justify-content-center'>
        <div className='headingabout'>
            <h4>Indulge in a captivating escape
               at The West Wind's
               Superior Room</h4>
        </div>
        </div>
        <br />
        <div className='container ' id='koplopolp'>
        <span className='jhgjgfvhghnvchgj'> Create cherished memories at The West Wind's Family Room. Nestled in the serene hills of Ooty, this spacious haven is designed for families seeking a captivating escape. With ample space and thoughtful amenities, it offers comfort and convenience for everyone. Wake up to the beauty of nature outside your window and embark on adventures in Ooty's lush landscapes. Whether it's quality time together or quiet moments of reflection, the Family Room provides a perfect setting. Experience a delightful blend of comfort and nature at The West Wind's Family Room</span>
</div>
    <br />
    <br />
        <div className='container headtwo '>
            <div>
                <div className='jhbhbhbh'>100m2</div>
                <hr className='ledthr' />
                <div className='jhbhbhbh'>4 Guests</div>
                <hr className='ledthr' />
                <div className='jhbhbhbh'>Shower</div>
                <hr className='ledthr' />
                <div className='jhbhbhbh'>Wake-up service</div>
                <hr className='ledthr' />
                <div className='jhbhbhbh'>Common Fireplace</div>
                <hr className='ledthr' />
                
    
            </div>
    
            <div>
            <div className='jhbhbhbh'>Mountain View</div>
            <hr />
                <div className='jhbhbhbh'>2 Bedroom with king size bed</div>
                <hr />
                <div className='jhbhbhbh'>Patio</div>
                <hr />
                <div className='jhbhbhbh'>Wi-Fi / internet access / Television</div>
                <hr />
                <div className='jhbhbhbh'>Breakfast / Dinner (On Request and Fixed Menu)</div>
                <hr />
            </div>
        </div>
        
    <a href="/Booking" ><div className=' container discovercon' >
    Book Now
    </div></a>
    <br />
    </div>

  
   
    
    
    <div className='aboutcontentpopo'>
        <div className='loploplop'> 
      <div className='linepopo'>
    
      </div>
        </div>
    </div>
    
    <div className='galley'>
      <h2>Gallery</h2>

</div>

<div className='aboutcontentpopo'>

<div className='loploploptwp' id='imagepoloplo'>
  <div className='Imgaegalop OneDounf'></div>
  <div className='Imgaegalop TwoDounf'></div>
  <div className='Imgaegalop ThreeDounf'></div>
  <div className='Imgaegalop FourDounf'></div>
  <div className='Imgaegalop FiveDounf'></div>
  <div className='Imgaegalop SixDounf'></div>
  </div>
</div>

    
    <div className='koplopop' >
   
    <div className=' jhgbujbh d-flex justify-content-center'>
  
    </div>
    
    <div  id='infocontact'>
      <div className='container d-flex justify-content-center'>
     <h1>Contact us to plan<br />your event</h1> 
     </div>
    <form>
    <div className='container'>
    <div className='row' id='formcon'>
      <div className='col-lg-3'></div>
      <div className='col-lg-3'>
      <input type='text' placeholder='Name' value={Name} onChange={(e)=>setName(e.target.value)} />
    <br />
    <br />
    <input type='text' placeholder='Surename' value={Surename} onChange={(e)=>setsurename(e.target.value)} />
    <br />
    <br />
    <input type='text' placeholder='Phone' value={Phone} onChange={(e)=>setphone(e.target.value)} />
    <br />
    <br />
    <input type='text' placeholder='Email' value={Email} onChange={(e)=>setemail(e.target.value)} />
  </div>

  <div className='col-lg-3 subjectone'>
    <input type='text' placeholder='Subject' value={Subject} onChange={(e)=>setsubject(e.target.value)} />
    <br />
    <br />
    <input type='text' placeholder='Message' value={Message} onChange={(e)=>setmessage(e.target.value)} />
      </div>
      <div className='col-lg-3'></div>
        
    </div>
    </div>
    
    </form>
    
    
    <div className=' container discovercon' onClick={conttactinfo}>
    Submit
    </div>
    <br />
    <br />
    </div>
    
      </div>  
    
    <Footer />
    </>
  )
}

export default Transition(ThirdRoom)