import React from 'react'
import Nav from './Navbar/Nav'
import Footer from './Footer/Footer'
import "./Terms.css"

function Gallery() {
  return (
    <>
    <Nav />
    <div className='galleryhead'>
    <div className='container d-flex justify-content-center successGal'>
   
   
  <div className='paoo mt-5'>TheWestWind Gallery</div>


</div>

<div className='newgallery'>
<div className='jhvjhjgbvhjgal'>
<div className='gallerydiv'><a href='/images/a001.png'><img src='/images/a001.png' alt='' /></a> </div>
<div className='gallerydiv'><a href='/images/a50.png'><img src='/images/a50.png' alt='' /> </a></div>
<div className='gallerydiv'><a href='/images/x2.png'><img src='/images/x2.png' alt='' /> </a></div>
<div className='gallerydiv'><a href='/images/x4.png'><img src='/images/x4.png' alt='' /> </a></div>
<div className='gallerydiv'><a href='/images/Blog.png'><img src='/images/Blog.png' alt='' /> </a></div>
<div className='gallerydiv'><a href='/images/dwp.png'><img src='/images/dwp.png' alt='' /> </a></div>
<div className='gallerydiv'><a href='/images/dwpi4.png'><img src='/images/dwpi4.png' alt='' /> </a></div>
<div className='gallerydiv'><a href='/images/f114.png'><img src='/images/f114.png' alt='' /> </a></div>
<div className='gallerydiv hbjnvjhv1'><a href='/images/H2.png'><img src='/images/H2.png' alt='' /></a> </div>
<div className='gallerydiv hbjnvjhv1'><a href='/images/x6.png'><img src='/images/x6.png' alt='' /> </a></div>
<div className='gallerydiv hbjnvjhv1'><a href='/images/x8.png'><img src='/images/x8.png' alt='' /> </a></div>
<div className='gallerydiv hbjnvjhv1'><a href='/images/x123.png'><img src='/images/x123.png' alt='' /> </a></div>



</div>
<br />

</div>
<br />
<br />
<br />
<br />
</div>
<Footer />
    </>
  )
}

export default Gallery