import React from 'react'
import Nav from '../Navbar/Nav'
import Footer from '../Footer/Footer'
import "./Success.css"

function Success() {
  return (
    <>
    <Nav />
    <div className='container d-flex justify-content-center success align-items-center'>
    <div>
    <div className='container d-flex justify-content-center hjgvjhvhv'>
<img src='/images/sui.png' alt='' />
    </div>
  <div className='paoo mt-5'>Payment Was Successful</div>
  <div className='oplop d-flex justify-content-center  mt-4'>Your Booking is Confirm......</div>
 <a href="/home"> <div className='koppo d-flex justify-content-center mt-4'>Redirecting to Home Page</div></a>
</div>
</div>
    <Footer />
    </>
  )
}

export default Success