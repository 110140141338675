import React, { useState } from 'react'
import "./About.css"
// import { Link } from 'react-router-dom'
import axios from 'axios'
import { Base_url } from '../Url';
// import { useNavigate } from 'react-router-dom';
import Footer from '../Footer/Footer';
import Nav from '../Navbar/Nav';
import Transition from '../Transition/Transition';

function FirstRoom() {

  // const navigate = useNavigate()

  const[Name , setName] = useState("")
  const[Email , setemail] = useState("")
  const[Surename , setsurename] = useState("")
  const[Phone , setphone] = useState("")
  const[Subject , setsubject] = useState("")
  const[Message , setmessage] = useState("")

  const conttactinfo =async()=>{
    const result = await axios.post(`${Base_url}/contact-details` , {
      Name : Name,
      Surename:Surename,
      Email : Email,
      Phone:Phone,
      Subject : Subject,
      Message:Message
     
    })

    alert(result.data.msg)
 
  }

  return (
    <>
    <Nav />
    <div className='hero'>
        <img src='/images/dwp.png' className='lan' alt='' />
        </div>
    
        <div className='herocenter'>
      <div className='container-fluid' >
       
        <div>
     <h3 className='text-light kdhhjbsfbw'>Double Room With Patio</h3>
      <div className='text-light  ukyjgubikhgb'>Enjoy a Double Room with Patio, offering amazing forest and landscape views.
      </div>
      </div>
      
      </div>
    
    
    </div>
    
    <div className='container-fluid aboutcontent'   >
        <br />
        <br />
        <div className='container d-flex justify-content-center'>
        <div className='headingabout'>
            <h4>Indulge in a captivating escape
               at The West Wind's Double
                Room with Patio.</h4>
        </div>
        </div>
        <br />
        <div className='container ' id='koplopolp'>
       <span className='jhgjgfvhghnvchgj'> Awaken your senses to the splendor of nature as you step onto your private patio, where mesmerizing forest and landscape vistas unfold before you. Imagine sipping your morning coffee amidst this natural symphony or unwinding with a glass of wine as the sun sets behind the trees. Our thoughtfully designed Double Room with Patio is a gateway to serenity, allowing you to immerse yourself in the beauty of Ooty's wilderness while enjoying the comfort and tranquility of our exceptional accommodations. Experience the fusion of nature and relaxation in a space that invites you to unwind and rejuvenate.</span>
        </div>
    <br />
    <br />
        <div className='container headtwo '>
            <div>
                <div className='jhbhbhbh'>100m2</div>
                <hr className='ledthr' />
                <div  className='jhbhbhbh'>3 Guests</div>
                <hr className='ledthr' />
                <div  className='jhbhbhbh'>Shower</div>
                <hr className='ledthr' />
                <div  className='jhbhbhbh'>Wake-up service</div>
                <hr className='ledthr' />
                <div  className='jhbhbhbh'>Common Fireplace</div>
                <hr className='ledthr' />
                
    
            </div>
    
            <div>
            <div  className='jhbhbhbh'>Mountain View</div>
            <hr />
                <div  className='jhbhbhbh'>Bedroom with king size bed</div>
                <hr />
                <div  className='jhbhbhbh'>Patio</div>
                <hr  />
                <div  className='jhbhbhbh'>Wi-Fi / internet access / Television</div>
                <hr  />
                <div  className='jhbhbhbh'>Breakfast / Dinner (On Request and Fixed Menu)</div>
                <hr />
            </div>
        </div>
        
   <a href="/Booking"> <div className=' container discovercon' >
    Book Now
    </div></a>
    <br />
    </div>

  
   
    
    
    <div className='aboutcontentpopo'>
        <div className='loploplop'> 
      <div className='linepopo'>
    
      </div>
        </div>
    </div>
    
    <div className='galley'>
      <h2>Gallery</h2>

</div>

<div className='aboutcontentpopo'>

  <div className='loploploptwp' id='imagepoloplo'>
  <div className='Imgaegalop OneDoun'></div>
  <div className='Imgaegalop TwoDoun'></div>
  <div className='Imgaegalop ThreeDoun'></div>
  <div className='Imgaegalop FourDoun'></div>
  <div className='Imgaegalop FiveDoun'></div>
  <div className='Imgaegalop SixDoun'></div>
  </div>
</div>

    
    <div className='koplopop' >
   
    <div className=' jhgbujbh d-flex justify-content-center'>
  
    </div>
    
    <div  id='infocontact'>
      <div className='container d-flex justify-content-center'>
     <h1>Contact us to plan<br />your event</h1> 
     </div>
    <form>
    <div className='container'>
    <div className='row' id='formcon'>
      <div className='col-lg-3'></div>
      <div className='col-lg-3'>
      <input type='text' placeholder='Name' value={Name} onChange={(e)=>setName(e.target.value)} />
    <br />
    <br />
    <input type='text' placeholder='Surename' value={Surename} onChange={(e)=>setsurename(e.target.value)} />
    <br />
    <br />
    <input type='text' placeholder='Phone' value={Phone} onChange={(e)=>setphone(e.target.value)} />
    <br />
    <br />
    <input type='text' placeholder='Email' value={Email} onChange={(e)=>setemail(e.target.value)} />
  </div>

  <div className='col-lg-3 subjectone'>
    <input type='text' placeholder='Subject' value={Subject} onChange={(e)=>setsubject(e.target.value)} />
    <br />
    <br />
    <input type='text' placeholder='Message' value={Message} onChange={(e)=>setmessage(e.target.value)} />
      </div>
      <div className='col-lg-3'></div>
        
    </div>
    </div>
    
    </form>
    
    
    <div className=' container discovercon' onClick={conttactinfo}>
    Submit
    </div>
    <br />
    <br />
    </div>
    
      </div>  
    
    <Footer />
    </>
  )
}

export default Transition(FirstRoom)